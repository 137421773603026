import React from "react";
import { graphql } from "gatsby";

import Layout from "../components/layout";

import Arrow from "../components/arrow";
import DayScheduleWrapper from "../components/schedule_components/wrapper";
import DayTitleArea from "../components/schedule_components/daytitle";
import ScheduleContainer from "../components/schedule_components/schedulecontainer";
import {
  Project,
  Lecture,
  Misc,
  Coreskill,
  Review,
  Survey,
} from "../components/schedule_components/allactivitytypes";

import Overlay from "../components/wipoverlay.js";
import WeekOneNav from "../components/rightnavs/weekoneRnav";
import WeekTwoNav from "../components/rightnavs/weektwoRnav";
import WeekThreeNav from "../components/rightnavs/weekthreeRnav";


//WeekThreeNav
var moment = require("moment")

let count = 0;
class ComponentGod {
  createProject(sarr) {
    let time = `${sarr[0].node.Start_Time} - ${sarr[sarr.length - 1].node.End_Time}`
    let ret = (
      <Project
        version={sarr[0].node.Sched_Ver}
        key={count}
        activitymateriallink={
          sarr[0].node.Link === "wip" ? "" : sarr[0].node.Link
        }
        activityname={sarr[0].node.Name_of_Activity}
        activitytime={time}
        activityres1link={sarr[0].node.Link_2 !== "" ? sarr[0].node.Link_2 : ""}
        activityres1name={
          sarr[0].node.Link_2_Name !== "" ? sarr[0].node.Link_2_Name : ""
        }
        activityres2link={sarr[0].node.Link_3 !== "" ? sarr[0].node.Link_3 : ""}
        activityres2name={
          sarr[0].node.Link_3_Name !== "" ? sarr[0].node.Link_3_Name : ""
        }
        activitydesc={sarr[0].node.Description}
      />
    )
    count++
    return ret
  }

  createCoreSkill(sarr) {
    let autoDescs = [
      "Partner Program",
      "Guest Speaker",
      "Mentorship",
      "Team Building",
    ];
    let coreDesc;
    if (autoDescs.includes(sarr[0].node.Name_of_Activity)) {
      switch (sarr[0].node.Name_of_Activity) {
        case "Partner Program":
          coreDesc = `These tech-specific or social-impact specific workshops are designed to deepen students' entrepreneurial, professional, and technical skills.`
          break;
        case "Guest Speaker":
          coreDesc = `Guest speakers are role models, entrepreneurs, and tech leaders who have been invited to visit our virtual classrooms and share their story through a casual and honest conversation.`
          break;
        case "Mentorship":
          coreDesc = `Students are given the opportunity to develop personally and professionally during these group mentoring sessions with volunteers from our partner companies.`
          break;
        case "Team Building":
          coreDesc = `Get to know your peers by having fun with daily team building activities!`;
          break;
        default:
          coreDesc = null;
          break;
      }
    }
    let time = `${sarr[0].node.Start_Time} - ${sarr[sarr.length - 1].node.End_Time}`
    let ret = (
      <Coreskill
        version={sarr[0].node.Sched_Ver}
        key={count}
        activitymateriallink={
          sarr[0].node.Link === "wip" || sarr[0].node.Link === "dne"
            ? ""
            : sarr[0].node.Link
        }
        activityname={sarr[0].node.Name_of_Activity}
        activitytime={time}
        activityres1link={sarr[0].node.Link_2 !== "" ? sarr[0].node.Link_2 : ""}
        activityres1name={
          sarr[0].node.Link_2_Name !== "" ? sarr[0].node.Link_2_Name : ""
        }
        activityres2link={sarr[0].node.Link_3 !== "" ? sarr[0].node.Link_3 : ""}
        activityres2name={
          sarr[0].node.Link_3_Name !== "" ? sarr[0].node.Link_3_Name : ""
        }
        activitydesc={coreDesc ? coreDesc : sarr[0].node.Description}
      />
    )
    count++
    return ret
  }

  createLecture(sarr) {
    let time = `${sarr[0].node.Start_Time} - ${sarr[sarr.length - 1].node.End_Time}`
    let ret = (
      <Lecture
        version={sarr[0].node.Sched_Ver}
        key={count}
        activitymateriallink={
          sarr[0].node.Link === "wip" || sarr[0].node.Link === "dne"
            ? ""
            : sarr[0].node.Link
        }
        activityname={sarr[0].node.Name_of_Activity}
        activitytime={time}
        activityres1link={sarr[0].node.Link_2 !== "" ? sarr[0].node.Link_2 : ""}
        activityres1name={
          sarr[0].node.Link_2_Name !== "" ? sarr[0].node.Link_2_Name : ""
        }
        activityres2link={sarr[0].node.Link_3 !== "" ? sarr[0].node.Link_3 : ""}
        activityres2name={
          sarr[0].node.Link_3_Name !== "" ? sarr[0].node.Link_3_Name : ""
        }
        activitydesc={sarr[0].node.Description}
      />
    )
    count++
    return ret
  }

  createReview(sarr) {
    let time = `${sarr[0].node.Start_Time} - ${sarr[sarr.length - 1].node.End_Time}`
    let ret = (
      <Review
        version={sarr[0].node.Sched_Ver}
        key={count}
        activitymateriallink={
          sarr[0].node.Link === "wip" || sarr[0].node.Link === "dne"
            ? ""
            : sarr[0].node.Link
        }
        activityname={sarr[0].node.Name_of_Activity}
        activitytime={time}
        activityres1link={sarr[0].node.Link_2 !== "" ? sarr[0].node.Link_2 : ""}
        activityres1name={
          sarr[0].node.Link_2_Name !== "" ? sarr[0].node.Link_2_Name : ""
        }
        activityres2link={sarr[0].node.Link_3 !== "" ? sarr[0].node.Link_3 : ""}
        activityres2name={
          sarr[0].node.Link_3_Name !== "" ? sarr[0].node.Link_3_Name : ""
        }
        activitydesc={sarr[0].node.Description}
      />
    )
    count++
    return ret
  }

  createMisc(sarr) {
    function getCorrectDesc(a) {
      switch (a) {
        case "OFFICE HOURS":
          return "Office hours is an extremely valuable resource. If you feel like there was a topic that was difficult, or you had a question that wasn’t answered, wanted to spend more time on a project, or even just want to use the room as a workspace, then office hours are for you! You’re strongly encouraged to use office hours to your advantage, you won’t regret it!"
        case "BREAK":
          return "Break Time! Sit back and relax 😎"
        case "LUNCH":
          return "Lunch time! Enjoy your food, get to know some of your peers, have fun!"
        default:
          return "";
      }
    }

    let miscDesc = getCorrectDesc(sarr[0].node.Name_of_Activity);
    let time = `${sarr[0].node.Start_Time} - ${sarr[sarr.length - 1].node.End_Time}`;
    let ret = (
      <Misc
        version={sarr[0].node.Sched_Ver}
        key={count}
        activitymateriallink="https://docs.google.com/document/d/1d_rmRQVvPwzWBUh2FZV6iHhGR_TASQcAeufTKkw452I/edit?usp=sharing"
        activityname={sarr[0].node.Name_of_Activity}
        activitytime={time}
        activityres1link={sarr[0].node.Link_2 !== "" ? sarr[0].node.Link_2 : ""}
        activityres1name={
          sarr[0].node.Link_2_Name !== "" ? sarr[0].node.Link_2_Name : ""
        }
        activityres2link=""
        activityres2name=""
        activitydesc={miscDesc}
      />
    )
    count++
    return ret
  }

  createSurvey(sarr) {
    let time = `${sarr[0].node.Start_Time} - ${sarr[sarr.length - 1].node.End_Time}`
    let ret = (
      <Survey
        version={sarr[0].node.Sched_Ver}
        key={count}
        activitymateriallink=""
        activityname={sarr[0].node.Name_of_Activity}
        activitytime={time}
        activityres1link=""
        activityres1name=""
        activityres2link=""
        activityres2name=""
        activitydesc={sarr[0].node.Description}
      />
    )
    count++
    return ret
  }
}
const cGod = new ComponentGod()

export default ({ data, pageContext }) => {
  const csvData = data.allSi2020SSoTCsv.edges
  const date = data.allSi2020SSoTCsv.edges[0].node.Date
  const day = pageContext.day
  let week, dayNum, dayTitle

  if (day <= 5) {
    week = "1"
  } else if (day <= 10) {
    week = "2"
  } else if (day <= 15) {
    week = "3"
  } else if (day <= 20) {
    week = "4"
  } else if (day <= 25) {
    week = "5"
  } else if (day <= 30) {
    week = "6"
  } else {
    week = "invalid_day"
  }

  if (day < 10) {
    dayNum = `0${day}`
  } else {
    dayNum = day
  }

  let dayOfWeek = 0
  if (day > 5) {
    dayOfWeek = day % 5 === 0 ? 5 : day % 5
  } else {
    dayOfWeek = day
  }
  dayOfWeek = dayOfWeek.toString()

  const dayTitles = [
    `$ The Beginning`,
    `dayOfSI === 2 ? 'yup' : 'not yet'`,
    `$ Loopdy Loop!`,
    `$ function learn("day4", student)`,
    `M.A.S.H.`,
    `Library Time`,
    `Coding Art`,
    `Doing more with p5`,
    `A new tool appears!`,
    `It's game time 😎`,
    `Intro to webdev!`,
    `Site 🚀`,
    `Positioning & More`,
    `You got a job already?!`,
    `Your very own website!`,
    `Putting all 3 together`,
    `Even more JS`,
    `Objects and Data`,
    `JSON and generating visuals`,
    `Your project Version 2.0`,
    `Intro to APIs`,
    `🔥Base`,
    `Using APIs`,
    `Custom Day`,
    `Demo Day Prep Day`,
    `Plenty of coding`,
    `Even more coding`,
    `Coding All Day`,
    `Last day of coding before the big day!`,
    `Demo Day & Graduation! 🎉🎊`
  ]
  if (day > dayTitles.length) {
    dayTitle = "WIP"
  } else {
    dayTitle = dayTitles[+day - 1]
  }

  const tabName = `W${week}D${day}`
  const currentData = csvData.filter(row => row.node.Day === day)

  let compressedMorning = [];
  let tempMorn = [];
  let compressedAfternoon = [];
  let tempAft = [];

  for (let i = 0; i < currentData.length; i++) {
    if(currentData[i].node.Sched_Ver === 'mo'){
      // let name = currentData[i].node.Name_of_Activity;
      // let type = currentData[i].node.Activity_Type;
      // let nextName = currentData[i+1].node.Name_of_Activity;
      // let nextType = currentData[i+1].node.Activity_Type;
      // if(type === nextType && name !== nextName){
      //   console.log(`This type: ${type} --- this name: ${name}\nNext type: ${nextType} --- Next name: ${nextName}`);
      //   tempMorn.push(currentData[i])
      //   console.log(tempMorn);
      //   compressedMorning.push(tempMorn);
      //   tempMorn = [];
      // }
      // if(name === "Downtime" && currentData[i+1].node.Name_of_Activity === "OFFICE HOURS"){
      //   tempMorn.push(currentData[i])
      //   compressedMorning.push(tempMorn);
      //   tempMorn = [];
      // }
    }
    if(currentData[i].node.Sched_Ver === 'mo' && currentData[i+1].node.Sched_Ver === "af"){
        tempMorn.push(currentData[i])
        tempMorn.shift();
        compressedMorning.push(tempMorn);
        tempMorn = [];
    }

    if(currentData[i].node.Sched_Ver === "mo"){
      if (tempMorn.length === 0) {
        tempMorn.push(currentData[i])
        continue
      }
      if (currentData[i].node.Activity_Type === currentData[i - 1].node.Activity_Type) {
          if (currentData[i].node.Name_of_Activity === currentData[i - 1].node.Name_of_Activity) {
            tempMorn.push(currentData[i])
          } else {
            compressedMorning.push(tempMorn)
            tempMorn = []
            tempMorn.push(currentData[i])
          }
        // if (currentData[i].node.Activity_Type === "Core Skill") {
        // } else {
        //   tempMorn.push(currentData[i])
        // }
      }
      if (currentData[i].node.Activity_Type !== currentData[i - 1].node.Activity_Type || currentData[i].node.Sched_Ver !== currentData[i - 1].node.Sched_Ver) {
        compressedMorning.push(tempMorn)
        tempMorn = []
        tempMorn.push(currentData[i])
      }
    }else {
      if (tempAft.length === 0) {
        tempAft.push(currentData[i])
        continue
      }
      if (currentData[i].node.Activity_Type === currentData[i - 1].node.Activity_Type) {
        if (currentData[i].node.Name_of_Activity === currentData[i - 1].node.Name_of_Activity) {
          tempAft.push(currentData[i])
        } else {
          compressedAfternoon.push(tempAft)
          tempAft = []
          tempAft.push(currentData[i])
        }
        // if (currentData[i].node.Activity_Type === "Core Skill") {
        // } else {
        //   tempAft.push(currentData[i])
        // }
      }
      if (currentData[i].node.Activity_Type !== currentData[i - 1].node.Activity_Type || currentData[i].node.Sched_Ver !== currentData[i - 1].node.Sched_Ver) {
        compressedAfternoon.push(tempAft)
        tempAft = []
        tempAft.push(currentData[i])
      }
      if(i === currentData.length-1){
        compressedAfternoon.push(tempAft);
        tempAft = [];
      }
    }
  }

  const compressed = compressedMorning.concat(compressedAfternoon).filter(sA => sA.length > 0);

  const concatenated = compressed.map((row, index) => {
    switch (row[0].node.Activity_Type) {
      case "Core Skill":
        return cGod.createCoreSkill(row)
      case "Project":
        return cGod.createProject(row)
      case "Misc":
        return cGod.createMisc(row)
      case "Lecture / Codealong":
        return cGod.createLecture(row)
      case "Review":
        return cGod.createReview(row)
      case "Survey":
        return cGod.createSurvey(row)
      default:
        return null
    }
  })
  // compressed.push({
  //   Activity_Type: 'Misc',
  //   End_Time: "5:00 PM",
  //   Start_Time: "4:00 PM",
  //   Name_of_Activity: "OFFICE HOURS"
  // })

  //DATE TEXT DAY NUM NEED FIX
  // moment = [year, month, day, hour, minute, second, millisecond]
  // default date = "mm/dd/yyyy"
  let dateArr = date.split("/")
  let actualDate = moment([
    parseInt(dateArr[2]),
    parseInt(dateArr[0]) - 1,
    parseInt(dateArr[1]),
  ])

  // console.log(compressed);
  // console.log(concatenated);

  // ANCHOR 🚩WEEKLY RELEASES HERE🚩
  const maxWeek = 6;

  // Final return statements
  if (dayTitle === "WIP" || +week > maxWeek) {
    return <Overlay></Overlay>
  } else {
    return (
      <Layout
        rightnav={
          +day <= 5 ? <WeekOneNav /> : +day <= 10 ? <WeekTwoNav /> : +day <= 15 ? <WeekThreeNav /> : null
        }
      >
        {/* <Arrow /> */}
        <DayScheduleWrapper>
          <DayTitleArea
            dateText={actualDate.format("MMMM Do")}
            dayofweek={dayOfWeek}
            weeknum={week}
            tabtitle={tabName}
            daynum={dayNum}
            titleText={dayTitle}
          />
          <ScheduleContainer>{concatenated}</ScheduleContainer>
        </DayScheduleWrapper>
      </Layout>
    )
  }
}

export const query = graphql`
  query {
    allSi2020SSoTCsv {
      edges {
        node {
          Activity_Type
          Date
          Day
          Description
          Start_Time
          End_Time
          Link
          Link_2
          Link_2_Name
          Link_3
          Name_of_Activity
          Link_3_Name
          Sched_Ver
        }
      }
    }
  }
`
